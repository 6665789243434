import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Strona nie istnieje" />
    <h1>Błąd 404</h1>
    <p>Strona nie istnieje</p>
  </Layout>
)

export default NotFoundPage
